import React, { useMemo, useCallback } from 'react';
import { Avatar, Typography } from '@mui/material';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import routes from 'routes';
import eventService from 'services/eventService';
import useDownloadPdf from 'hooks/common/useDownloadPdf';
import Suspense from 'components/common/Suspense';
import InfoCardWidget from 'components/common/InfoCardWidget';
import {
	getPdfMedications,
	mapMedicationOnboardingAlert,
	medsGroupedByPacks,
} from 'core/api/medications/medications.api';
import { MainSidebarMenu, MenuItem, ContentCard } from 'components/patient/common';
import SidebarList from 'components/common/sidebarList/SidebarList.styles';
import SidebarListItem from 'components/common/sidebarList/SidebarListItem.styles';
import { RefillIcon } from 'components/common/icons/RefillIcon';
import { useUserContext } from 'contexts/UserProvider';
import { useGetMedications } from 'hooks/patient/useMedications';
import { MedicationOnboardingAlert } from 'core/api/medications/medications.models';

export function MedicationSidebar() {
	const { sfid: activeSfid } = useParams();
	const { data, isLoading: medicationsIsLoading } = useGetMedications();
	const { medications = [], anyOrderReceived = false } = data || {};

	const medsGroupedByPacksData = useMemo(() => {
		return medications.length > 0 ? medsGroupedByPacks(medications) : null;
	}, [medications]);

	const { isLoading, downloadPdfAction } = useDownloadPdf({ fetchPdfData: getPdfMedications });
	const { badges } = useUserContext();

	const handleDownloadPdf = useCallback(() => {
		eventService.emitEvent({ category: 'button', action: 'medicationListDownloadPdf' });
		downloadPdfAction();
	}, [downloadPdfAction]);

	const menuItems = useMemo(
		() =>
			[
				{
					label: 'Refill Alerts',
					icon: <RefillIcon fontSize="large" />,
					to: generatePath(routes.medication),
					state: { showSidebarNavigation: false },
					activeRoutes: [generatePath(routes.medication)],
					alert: !!badges.refillAlertsBadge,
					helperText: (
						<Typography variant="body1" color="grey.600">
							{(!!badges.refillAlertsBadge && 'Action Required') || 'None'}
						</Typography>
					),
				},
				!!medsGroupedByPacksData && {
					label: isLoading ? 'Loading...' : 'Print Medication List',
					disabled: isLoading,
					dense: true,
					onClick: handleDownloadPdf,
				},
			].filter(Boolean) as MenuItem[],
		[badges.refillAlertsBadge, medsGroupedByPacksData, isLoading, handleDownloadPdf]
	);

	const mappedAlerts = useCallback(
		(onboardingAlerts?: MedicationOnboardingAlert[] | null) =>
			onboardingAlerts ? mapMedicationOnboardingAlert(onboardingAlerts) : [],
		[]
	);

	return (
		<ContentCard title="My Medication">
			<Suspense isLoading={medicationsIsLoading}>
				{!medsGroupedByPacksData && anyOrderReceived && (
					<Typography variant="body1" color="text.secondary">
						Please call us at 1-877-355-7225. We need you to call us before we can send your next order.
					</Typography>
				)}
				{!medsGroupedByPacksData && !anyOrderReceived && (
					<Typography variant="body1" color="text.secondary">
						We are working on getting your prescriptions transferred to us. We will add your medications as we get them
						from your doctor. Your entire medication list will be here after your first monthly order has been shipped.
					</Typography>
				)}
				<MainSidebarMenu menuItems={menuItems} />
				{Object.entries(medsGroupedByPacksData || {}).map(([title, meds]) => (
					<SidebarList key={title}>
						<Typography component="h2" variant="h2" fontWeight="bold" color="text.secondary" sx={{ pt: '5px' }}>
							{title}
						</Typography>
						{meds.map(({ sfid, name, imageUrl, refillAlert, onboardingAlerts }) => (
							<SidebarListItem
								key={sfid}
								to={generatePath(routes.medication, {
									sfid,
								})}
								state={{ showSidebarNavigation: false }}
								component={RouterLink}
								className={activeSfid && activeSfid === sfid ? 'active' : ''}
							>
								<InfoCardWidget
									icon={
										imageUrl ? (
											<Avatar src={imageUrl} alt={name || ''} variant="rounded" sx={{ width: 36, height: 36 }} />
										) : undefined
									}
									title={name || ''}
									alert={!!refillAlert || !!mappedAlerts(onboardingAlerts)?.length}
								/>
							</SidebarListItem>
						))}
					</SidebarList>
				))}
			</Suspense>
		</ContentCard>
	);
}

export default MedicationSidebar;
