import React, { useMemo } from 'react';
import { UserRole } from 'core/api/userAccount/userAccount.models';
import UnauthorizedAccessPage from 'pages/errors/UnauthorizedAccessPage';
import { useAuthContext, Token } from 'contexts/AuthProvider';

interface ProtectProps {
	children: React.ReactNode;
	condition?: ({ userToken }: { userToken: Token }) => boolean;
	fallback?: React.ReactNode;
	roles?: UserRole[];
}

function Protect({ children, condition, fallback, roles = [] }: ProtectProps) {
	const { jwtToken, jwtPayload } = useAuthContext();

	const hasAccess = useMemo(() => {
		return (
			(!condition || condition({ userToken: jwtToken })) &&
			(!roles.length || jwtPayload?.roles?.some((role) => roles.includes(role as UserRole)))
		);
	}, [condition, jwtToken, jwtPayload, roles]);

	const fallbackElement = fallback || <UnauthorizedAccessPage />;

	return hasAccess ? children : fallbackElement;
}

export default Protect;
