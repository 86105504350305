import React, { useCallback } from 'react';
import AccessibleIcon from '@mui/icons-material/Accessible';
import BusinessIcon from '@mui/icons-material/Business';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import routes from 'routes';
import { DashboardLayout } from 'components/common/dashboardLayout';
import { useAuthContext } from 'contexts/AuthProvider';
import { useAppContext } from 'contexts/AppProvider';

interface AdminLayoutProps {
	children: React.ReactNode;
}

export function AdminLayout({ children }: AdminLayoutProps) {
	const { signOut } = useAuthContext();
	const { pageTitle } = useAppContext();
	const email = 'Admin';

	const handleSignOut = useCallback(() => {
		signOut('ecaAdminOAuthProvider');
	}, [signOut]);

	const mainMenu = [
		{
			label: 'Patients',
			icon: <AccessibleIcon />,
			to: routes.admin.patients.list,
			activeRoutes: [
				routes.admin.patients.list,
				routes.admin.patients.create,
				routes.admin.patients.patient.summary,
				routes.admin.patients.patient.medSync,
				routes.admin.patients.patient.notifications,
				routes.admin.patients.patient.metaData,
			],
		},
		{
			label: 'Enterprises',
			icon: <BusinessIcon />,
			to: routes.admin.enterprise.list,
			activeRoutes: [routes.admin.enterprise.list, routes.admin.enterprise.caregiver.list],
		},
		{
			label: 'Blocked Logins',
			icon: <LockPersonIcon />,
			to: routes.admin.blockedLogins,
			activeRoutes: [routes.admin.blockedLogins],
		},
	];

	return (
		<DashboardLayout {...{ pageTitle, mainMenu, email, logout: handleSignOut }} className="EcaLayout-admin">
			{children}
		</DashboardLayout>
	);
}

export default AdminLayout;
