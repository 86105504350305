import { getRequest, postRequest } from 'core/api/api-service';
import { UserRole } from 'core/api/userAccount/userAccount.models';
import { AuthProviderBase } from 'services/authService/authService';

export interface OAuthConfig {
	authorizationEndpoint: string;
	tokenEndpoint: string;
	signOutEndpoint: string;
	userEndpoint: string;
}

interface SalesforceAuthResponse {
	token: string;
	refresh_token: string;
}

export interface User {
	email: string;
	timeZone: string;
	roles: UserRole[];
}

export class EcaAdminOAuthProvider implements AuthProviderBase {
	private static instance: EcaAdminOAuthProvider | null = null;

	private readonly config: OAuthConfig;

	public name = 'ecaAdminOAuthProvider';

	private user: User | null = null;

	private constructor(config: OAuthConfig) {
		this.config = config;
	}

	public static getInstance(config?: OAuthConfig): EcaAdminOAuthProvider {
		if (!EcaAdminOAuthProvider.instance) {
			if (!config) {
				throw new Error('Configuration is required when creating the first instance');
			}
			EcaAdminOAuthProvider.instance = new EcaAdminOAuthProvider(config);
		}
		return EcaAdminOAuthProvider.instance;
	}

	public async authorize(code: string): Promise<SalesforceAuthResponse>;
	public async authorize(code?: string): Promise<SalesforceAuthResponse | void> {
		if (code) {
			const salesforceAuthResponse = await getRequest<SalesforceAuthResponse>(this.config.tokenEndpoint, {
				code: encodeURIComponent(code),
			});

			const user = await getRequest<User>(
				this.config.userEndpoint,
				{},
				{
					authorization: `Bearer ${salesforceAuthResponse.token}`,
				}
			);

			this.user = user;

			return salesforceAuthResponse;
		}

		window.location.href = this.config.authorizationEndpoint;
	}

	public async signOut(): Promise<void> {
		await postRequest(this.config.signOutEndpoint);
	}

	public getUser(): User | null {
		return this.user;
	}
}
