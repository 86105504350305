import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Alert, Button, Box, Typography } from '@mui/material';
import { useUserAccountMutations } from 'hooks/patient/useUserAccount';
import { useUserContext } from 'contexts/UserProvider';

export function DeleteAccount() {
	const { programmaticLogout } = useUserContext();
	const { deleteAccountMutation } = useUserAccountMutations();
	const { mutate, isPending, isSuccess, error } = deleteAccountMutation;
	const [checked, setChecked] = useState<boolean>(false);
	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
	};
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		mutate(undefined, { onSuccess: () => setTimeout(() => programmaticLogout(), 5000) });
	};

	return (
		<>
			{isSuccess && (
				<Box>
					<Typography component="p" color={(theme) => theme.palette.success.main}>
						Your online account access has been deleted.
					</Typography>
					<Typography component="p" mt={2}>
						This does not cancel your pharmacy service with ExactCare.
					</Typography>
					<Typography component="p" mt={2}>
						If you have questions, please call 1-877-355-7225.
					</Typography>
				</Box>
			)}
			{!isSuccess && (
				<Box component="form" onSubmit={handleSubmit}>
					<Typography component="p">
						This request will delete online access to your medication and account information. This will not stop your
						pharmacy service. <br />
						Questions? Call us! 1-877-355-7225
					</Typography>

					{error?.message && (
						<Alert severity="error" sx={{ mt: 2 }}>
							{error.message}
						</Alert>
					)}

					<Box mt={2}>
						<FormControlLabel
							sx={{ alignItems: 'flex-start', '& .MuiFormControlLabel-label': { fontWeight: 700 } }}
							control={<Checkbox checked={checked} onChange={handleCheckboxChange} name="confirmDeleteAccount" />}
							label="Please delete my online account access. I understand this does not cancel my service with ExactCare."
						/>
					</Box>

					<Box mt={2} mb={2}>
						<Button disabled={!checked || isPending} variant="outlined" color="primary" type="submit">
							{isPending ? 'Loading...' : 'Delete Account'}
						</Button>
					</Box>
				</Box>
			)}
		</>
	);
}

export default DeleteAccount;
