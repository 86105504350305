import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	changeEmailRequest,
	resendVerificationEmailRequest,
	verifyEmailChangedRequest,
} from 'core/api/userAccount/userAccount.api';
import { userAccountQueries } from 'queries/patient';

export function useAccountEmailMutation() {
	const queryClient = useQueryClient();

	const changeEmailMutation = useMutation({
		mutationFn: changeEmailRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: userAccountQueries.userAccountProfile().queryKey });
		},
	});

	const verifyEmailChangedMutation = useMutation({
		mutationFn: verifyEmailChangedRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: userAccountQueries.userAccountProfile().queryKey });
		},
	});

	const resendVerificationEmailMutation = useMutation({
		mutationFn: resendVerificationEmailRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: userAccountQueries.userAccountProfile().queryKey });
		},
	});

	return {
		changeEmailMutation,
		verifyEmailChangedMutation,
		resendVerificationEmailMutation,
	};
}

export default useAccountEmailMutation;
