import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function SalesforceIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M15.1823 12.0413C15.2625 11.9197 15.3033 11.7476 15.3033 11.5292C15.3033 11.3111 15.2625 11.1393 15.1826 11.019C15.103 10.8999 14.983 10.8419 14.8157 10.8419C14.648 10.8419 14.5286 10.8999 14.4506 11.019C14.3716 11.1393 14.3315 11.3111 14.3315 11.5292C14.3315 11.7476 14.3716 11.92 14.4506 12.0413C14.5286 12.1619 14.648 12.2202 14.8157 12.2202C14.983 12.2202 15.103 12.1616 15.1823 12.0413Z"
				fill="currentColor"
			/>
			<path
				d="M19.5355 11.0191C19.4838 11.0977 19.4503 11.1987 19.4324 11.3199H20.3419C20.3328 11.2031 20.3099 11.0977 20.2573 11.0191C20.178 10.9 20.0674 10.8342 19.9 10.8342C19.7326 10.8342 19.6136 10.8997 19.5355 11.0191Z"
				fill="currentColor"
			/>
			<path
				d="M9.32318 11.0191C9.27148 11.0977 9.23824 11.1987 9.22008 11.3199H10.1295C10.1204 11.2031 10.0975 11.0977 10.0452 11.0191C9.96563 10.9 9.85499 10.8342 9.68765 10.8342C9.5203 10.8342 9.40121 10.8997 9.32318 11.0191Z"
				fill="currentColor"
			/>
			<path
				d="M6.39015 12.0794C6.40706 12.1051 6.41395 12.1151 6.46379 12.1549C6.46284 12.1546 6.57817 12.2452 6.83766 12.2295C7.02004 12.2185 7.18175 12.1837 7.18175 12.1837V11.604C7.18175 11.604 7.01848 11.577 6.83578 11.5745C6.57568 11.5711 6.46507 11.667 6.46599 11.6666C6.3892 11.7209 6.35223 11.8014 6.35223 11.9136C6.35223 11.9844 6.36506 12.0402 6.39015 12.0794Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.9449 4.78232C11.8524 4.78232 10.8646 5.24084 10.1551 5.98012C9.37442 4.9594 8.14311 4.30065 6.75761 4.30065C4.39778 4.30065 2.48453 6.21106 2.48453 8.56775C2.48453 9.17104 2.61021 9.74487 2.83615 10.2648C1.73866 10.9063 1 12.1053 1 13.4786C1 15.5279 2.64405 17.1888 4.672 17.1887C4.93087 17.1887 5.18315 17.1618 5.42665 17.1101C5.98385 18.6217 7.43547 19.6993 9.13874 19.6993C10.7743 19.6993 12.1777 18.706 12.78 17.2891C13.2382 17.5126 13.7528 17.6381 14.2969 17.6381C15.5965 17.6381 16.7288 16.922 17.3229 15.8622C17.6305 15.9243 17.9434 15.9555 18.2572 15.9553C20.8765 15.9553 23 13.8133 23 11.1708C23 8.52859 20.8765 6.38655 18.2572 6.38655C17.5743 6.38655 16.9253 6.5323 16.3389 6.79427C15.6642 5.59211 14.3971 4.78232 12.9449 4.78232Z"
				fill="currentColor"
			/>
			<path
				d="M13.9857 9.69029C14.034 9.69937 14.071 9.70784 14.1117 9.72038C14.1205 9.7238 14.1453 9.73602 14.1352 9.76493L14.0378 10.0326C14.0296 10.0526 14.024 10.0648 13.9823 10.0523C13.9719 10.0491 13.9566 10.0448 13.9171 10.0363C13.8886 10.0303 13.8507 10.026 13.8124 10.026C13.762 10.026 13.7159 10.0322 13.6755 10.0454C13.636 10.0582 13.5999 10.0805 13.5686 10.1118C13.5369 10.1438 13.4993 10.1905 13.4793 10.2479C13.4395 10.363 13.4202 10.4843 13.414 10.5232L13.4119 10.5355H13.8196C13.8538 10.5355 13.8644 10.5512 13.8613 10.5766L13.8137 10.8417C13.8064 10.8802 13.771 10.879 13.771 10.879H13.3511L13.0637 12.5046C13.0336 12.6725 12.9957 12.8167 12.9515 12.9327C12.907 13.0502 12.8603 13.136 12.786 13.2182C12.7171 13.294 12.6394 13.3501 12.5504 13.3824C12.4617 13.4143 12.3545 13.4309 12.2373 13.4309C12.1812 13.4309 12.1214 13.43 12.0505 13.4134C11.9994 13.4012 11.9715 13.3924 11.9339 13.3792C11.918 13.3733 11.9054 13.3535 11.9142 13.3282C11.9233 13.3028 11.9985 13.0962 12.0089 13.069C12.022 13.0361 12.0549 13.0486 12.0549 13.0486C12.0778 13.0583 12.0938 13.0649 12.1239 13.0709C12.1543 13.0771 12.1953 13.0822 12.226 13.0822C12.2815 13.0822 12.3323 13.0753 12.3761 13.0605C12.4294 13.0427 12.4602 13.012 12.4927 12.9706C12.5266 12.9273 12.5538 12.8681 12.5817 12.7891C12.6103 12.7089 12.636 12.6033 12.6582 12.4748L12.944 10.879H12.6623C12.6285 10.879 12.6175 10.863 12.6209 10.8379L12.6682 10.5725C12.6758 10.5343 12.7109 10.5355 12.7109 10.5355H13.0004L13.0161 10.4493C13.0593 10.1933 13.1455 9.99866 13.2725 9.87078C13.4 9.7423 13.5818 9.67711 13.8125 9.67711C13.8786 9.67711 13.9368 9.68153 13.9857 9.69029Z"
				fill="currentColor"
			/>
			<path
				d="M8.37841 12.5168C8.39973 12.5168 8.41508 12.4996 8.41508 12.4783V9.75672C8.41508 9.73544 8.39973 9.71819 8.37841 9.71819H8.04026C8.01895 9.71819 8.00391 9.73544 8.00391 9.75672V12.4783C8.00391 12.4996 8.01895 12.5168 8.04026 12.5168H8.37841Z"
				fill="currentColor"
			/>
			<path
				d="M4.1927 12.3325C4.18612 12.3262 4.1752 12.3159 4.18661 12.2861L4.27569 12.0391C4.28978 11.9965 4.32207 12.0106 4.33491 12.0188C4.34531 12.025 4.35463 12.0311 4.36435 12.0374C4.37747 12.0459 4.3913 12.0548 4.40949 12.0654C4.6718 12.2312 4.91468 12.2328 4.99083 12.2328C5.18702 12.2328 5.30861 12.1288 5.30861 11.989V11.9815C5.30861 11.8287 5.12191 11.7713 4.90503 11.7046L4.85639 11.6897C4.55834 11.6045 4.24026 11.4822 4.24026 11.1052V11.0977C4.24026 10.7401 4.52857 10.4907 4.94161 10.4907L4.98673 10.4904C5.22928 10.4904 5.46371 10.5609 5.63325 10.664C5.6486 10.6734 5.66364 10.6909 5.65519 10.7148C5.64735 10.7367 5.57212 10.9391 5.56336 10.9617C5.54737 11.004 5.50381 10.9758 5.50381 10.9758C5.35527 10.8937 5.12461 10.8291 4.93031 10.8291C4.75544 10.8291 4.64262 10.9219 4.64262 11.0479V11.0557C4.64262 11.203 4.83544 11.2656 5.05905 11.3383L5.09797 11.3509C5.39476 11.4446 5.71095 11.5744 5.71095 11.9317V11.9392C5.71095 12.3256 5.43078 12.5656 4.97951 12.5656C4.75795 12.5656 4.54607 12.5309 4.32169 12.4118L4.2954 12.397C4.26199 12.3783 4.22879 12.3598 4.19601 12.3359C4.19526 12.3349 4.19407 12.3337 4.1927 12.3325Z"
				fill="currentColor"
			/>
			<path
				d="M10.7992 12.3325C10.7927 12.3262 10.7817 12.3159 10.7932 12.2861L10.8822 12.0391C10.8953 11.9987 10.9336 12.0134 10.9417 12.0188L10.9568 12.0285C10.9744 12.04 10.9912 12.051 11.016 12.0654C11.2783 12.2312 11.5212 12.2328 11.5973 12.2328C11.7935 12.2328 11.9154 12.1288 11.9154 11.989V11.9815C11.9154 11.8288 11.7286 11.7714 11.5119 11.7048L11.4629 11.6897C11.1648 11.6045 10.8467 11.4822 10.8467 11.1052V11.0977C10.8467 10.7401 11.135 10.4907 11.5481 10.4907L11.5932 10.4904C11.8358 10.4904 12.0702 10.5609 12.2397 10.664C12.2551 10.6734 12.2702 10.6909 12.2617 10.7148C12.2538 10.7367 12.1786 10.9391 12.1698 10.9617C12.1539 11.004 12.1103 10.9758 12.1103 10.9758C11.9618 10.8937 11.7311 10.8291 11.5368 10.8291C11.3619 10.8291 11.2491 10.9219 11.2491 11.0479V11.0557C11.2491 11.2029 11.4419 11.2656 11.6653 11.3382L11.7045 11.3509C12.0013 11.4446 12.3175 11.5744 12.3175 11.9317V11.9392C12.3175 12.3256 12.0373 12.5656 11.586 12.5656C11.3645 12.5656 11.1526 12.5309 10.9282 12.4118L10.9018 12.3969C10.8685 12.3783 10.8353 12.3598 10.8026 12.3359C10.8021 12.3353 10.8015 12.3346 10.8008 12.3339C10.8003 12.3335 10.7998 12.333 10.7992 12.3325Z"
				fill="currentColor"
			/>
			<path
				d="M15.5086 10.7971C15.5853 10.8889 15.643 10.9992 15.68 11.1243C15.7169 11.2487 15.7355 11.385 15.7355 11.5292C15.7355 11.6734 15.717 11.8097 15.68 11.9341C15.643 12.0591 15.5853 12.1695 15.5086 12.2613C15.4318 12.3534 15.3343 12.4271 15.2193 12.4794C15.104 12.5317 14.9683 12.5584 14.8157 12.5584C14.6627 12.5584 14.527 12.5317 14.4117 12.4794C14.2967 12.4271 14.1992 12.3534 14.1224 12.2613C14.0453 12.1692 13.988 12.0588 13.9507 11.9341C13.914 11.81 13.8955 11.6737 13.8955 11.5292C13.8955 11.3847 13.914 11.2487 13.9507 11.1243C13.988 10.9996 14.0457 10.8893 14.1221 10.7971C14.1992 10.705 14.2964 10.631 14.4117 10.5771C14.5267 10.5235 14.6627 10.4963 14.8157 10.4963C14.9683 10.4963 15.1043 10.5235 15.2193 10.5771C15.3346 10.631 15.4318 10.705 15.5086 10.7971Z"
				fill="currentColor"
			/>
			<path
				d="M18.7254 12.139C18.7254 12.139 18.7608 12.1252 18.7733 12.1619L18.8658 12.4173C18.8777 12.449 18.8504 12.4621 18.8504 12.4621C18.7082 12.5182 18.5101 12.5574 18.3171 12.5574C17.9905 12.5574 17.7401 12.4631 17.5734 12.2775C17.407 12.0926 17.3227 11.84 17.3227 11.5276C17.3227 11.3831 17.3433 11.2465 17.3841 11.1221C17.4251 10.9973 17.4865 10.887 17.5674 10.7949C17.6482 10.7027 17.7504 10.6288 17.8708 10.5752C17.9914 10.5213 18.1324 10.4943 18.2907 10.4943C18.3966 10.4943 18.4919 10.5009 18.5734 10.5135C18.6605 10.5266 18.7758 10.5576 18.8247 10.5767C18.8335 10.5802 18.8579 10.5924 18.8479 10.6209C18.8244 10.6883 18.8056 10.7399 18.7859 10.7942L18.7554 10.8786C18.7413 10.9174 18.7116 10.9046 18.7116 10.9046C18.5875 10.8654 18.469 10.8475 18.3136 10.8475C18.1271 10.8475 17.9874 10.9099 17.8955 11.0315C17.8031 11.1537 17.7517 11.3142 17.751 11.5276C17.7504 11.762 17.8087 11.9353 17.9128 12.0425C18.0165 12.1497 18.1613 12.2039 18.3437 12.2039C18.4179 12.2039 18.4872 12.1989 18.5502 12.1892C18.6122 12.1795 18.6705 12.16 18.7254 12.139Z"
				fill="currentColor"
			/>
			<path
				d="M20.5374 10.7549C20.5895 10.8107 20.6688 10.9326 20.7007 11.0532C20.7772 11.3219 20.7449 11.5563 20.7393 11.597L20.7386 11.6026C20.7351 11.6352 20.7016 11.6358 20.7016 11.6358L19.4265 11.6346C19.4346 11.8282 19.4807 11.9655 19.575 12.0589C19.6668 12.1501 19.8135 12.2084 20.0112 12.2087C20.2873 12.2093 20.4194 12.1587 20.5097 12.1241L20.5346 12.1147C20.5346 12.1147 20.5701 12.1018 20.5826 12.1366L20.6659 12.3701C20.6829 12.4096 20.6694 12.4234 20.6553 12.4312C20.5754 12.4754 20.3814 12.5575 20.0131 12.5587C19.8342 12.5591 19.6784 12.5334 19.5503 12.4838C19.4211 12.4341 19.3133 12.3626 19.2297 12.2714C19.1457 12.1808 19.0836 12.0715 19.0448 11.9473C19.0062 11.8242 18.9868 11.6872 18.9868 11.5402C18.9868 11.3958 19.0056 11.2588 19.0429 11.1332C19.0799 11.0069 19.1381 10.8956 19.2156 10.8022C19.293 10.7085 19.3917 10.6333 19.5092 10.5781C19.6264 10.523 19.7709 10.4963 19.9301 10.4963C20.0667 10.4963 20.1915 10.5258 20.2955 10.5703C20.3751 10.6048 20.4553 10.6665 20.5374 10.7549Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
