import React from 'react';
import { Outlet } from 'react-router-dom';
import AppProvider from 'contexts/AppProvider';

function AdminApp() {
	return (
		<AppProvider>
			<Outlet />
		</AppProvider>
	);
}

export default AdminApp;
