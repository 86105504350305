import { HydraCollection } from '../api-service.models';

export enum UserRole {
	ADMIN = 'ROLE_ADMIN',
	ENTERPRISE_CARE_TEAM_MEMBER = 'ROLE_ENTERPRISE_CARE_TEAM_MEMBER',
	ENTERPRISE_SUPERVISOR = 'ROLE_ENTERPRISE_SUPERVISOR',
	PATIENT = 'ROLE_PATIENT',
}

interface IAPIResponse {
	'@context': string;
	'@id': string;
	'@type': string;
}

export interface IShippingAddress {
	shippingStreet: string;
	shippingCity: string;
	shippingStateCode: string;
	shippingPostalCode: string;
}

export interface IBillingAddress {
	billingStreet: string;
	billingCity: string;
	billingStateCode: string;
	billingPostalCode: string;
}

export interface IAddressParts extends IShippingAddress, IBillingAddress {}

export interface ProfileInfoHomePage {
	exactPack: {
		orderId: number;
		period: string;
		completePercentage: number;
	};
	medsThisWeek: {
		day: string;
		status: string;
		message: string;
	}[];
}

export interface IProfileInfo {
	requestedShippingAddress: string | null;
	requestedBillingAddress: string | null;
	phoneNumber: string | null;
	pendingPhoneNumber: string | null;
	changePhoneRequestId: number | null;
	email: string | null;
	emailValidated: boolean | null;
	shippingAddress: string | null;
	billingAddress: string | null;
	changeAddress: IAddressParts | null;
	balance: string | null;
	balanceFloat: number | null;
	balanceUpdatedAt: string | null;
	fullName: string | null;
	firstName: string | null;
	passwordExpired: boolean | null;
	roles: UserRole[];
	timeZone: string | null;
	medicationRemindersEnabled: boolean | null;
	homepage: ProfileInfoHomePage | null;
	vialsPatient: boolean | null;
}

export interface IGetProfileInfoResponse extends IProfileInfo, IAPIResponse {}

export interface ISendChangePhoneRequest {
	phoneNumber: string | null;
}

export interface ISendChangePhoneRequestResponse extends IAPIResponse {
	id: number | null;
	phoneNumber: string | null;
}

export interface IDeleteChangePhoneRequest {
	id: number | null;
}

export interface ISuccessResponse {
	success: boolean;
}

export interface ISendCodeAgainRequest {
	id: number | null;
}

export interface ISendCodeAgainRequestResponse extends ISendChangePhoneRequestResponse {}

export interface IVerifyPhoneNumberRequest {
	phoneVerificationCode: string | null;
}

export interface IChangeEmailRequest {
	newEmail: string;
	password: string;
}

export interface IVerifyEmailChangedRequest {
	hash: string;
}

export interface IChangePasswordRequest {
	password: string;
	newPassword: string;
}

export interface IChangeAddressRequest {
	shippingStreet: string;
	shippingCity: string;
	shippingStateCode: string;
	shippingPostalCode: string;
	billingStreet: string;
	billingCity: string;
	billingStateCode: string;
	billingPostalCode: string;
}

export interface IChangeAddressResponse extends IAPIResponse {
	requestedShippingAddress: string | null;
	requestedBillingAddress: string | null;
}

export interface IChangeEmailResponse extends IAPIResponse {
	jwtToken: string;
}

export interface IMakeAPaymentResponse extends IAPIResponse {
	url: string;
}

export enum NotificationChannel {
	EMAIL = 2,
	PUSH = 3,
}

export interface NotificationSettings {
	categories: {
		id: number;
		title: string;
		description: string;
	}[];
	channels: {
		id: NotificationChannel;
		title: string;
	}[];
	values: {
		categoryId: number;
		channelId: NotificationChannel;
		enabled: boolean;
	}[];
	enrolledInSMS: boolean;
	contactMobilePhone: string;
}

export interface NotificationSettingProps {
	categoryId: number;
	channelId: NotificationChannel;
	enabled: boolean;
}

export enum UserMetaDataKeys {
	WALKTHROUGH = 'WALKTHROUGH_WEB',
}

export interface UserMetaDataParams {
	key?: UserMetaDataKeys | UserMetaDataKeys[];
}

export interface UserMetaData {
	id: number;
	key: UserMetaDataKeys;
	value: string;
}

export interface UserMetaDataResponse extends HydraCollection<UserMetaData> {}

export interface CreateUserMetaDataParams {
	key: UserMetaDataKeys;
	value: string;
}
export interface UpdateUserMetaDataParams extends UserMetaData {}
