import { queryOptions } from '@tanstack/react-query';
import { getInit } from 'core/api/init/init.api';
import authService from 'services/authService';

export const initQuery = () => {
	const token = authService.getToken();

	return queryOptions({
		queryKey: ['init'],
		queryFn: getInit,
		enabled: !!token,
	});
};

export default initQuery;
