import React, { createContext, useContext, useMemo, useState, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';

export interface IAppContext {
	isLoading: boolean;
	pageTitle: string;
	setPageTitle: (title: string | undefined) => void;
}

export const AppContext = createContext<IAppContext | undefined>(undefined);

export const useAppContext = (): IAppContext => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('useAppContext must be used within a AppProvider');
	}
	return context;
};

interface PageTitleProps {
	children: string;
}

export function PageTitle({ children }: PageTitleProps): null {
	const { setPageTitle } = useAppContext();

	useLayoutEffect(() => {
		setPageTitle(children);
		return () => setPageTitle(undefined);
	}, [children, setPageTitle]);

	return null;
}

interface AppProviderProps {
	children: React.ReactNode;
	defaultTitle?: string;
}

function AppProvider({ children, defaultTitle = 'ExactCare' }: AppProviderProps) {
	const [pageTitle, setPageTitle] = useState<string>(defaultTitle);

	const value = useMemo(
		() => ({
			pageTitle,
			setPageTitle,
		}),
		[pageTitle, setPageTitle]
	) as IAppContext;

	return (
		<AppContext.Provider value={value}>
			<Helmet>
				<title>{pageTitle || defaultTitle}</title>
			</Helmet>
			{children}
		</AppContext.Provider>
	);
}

AppProvider.PageTitle = PageTitle;

export default AppProvider;
