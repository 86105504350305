import React from 'react';
import { PatientList } from 'components/enterprise';
import { Grid } from '@mui/material';
import AppProvider from 'contexts/AppProvider';

function PatientsPage() {
	return (
		<Grid item xs={12}>
			<AppProvider.PageTitle>Patients</AppProvider.PageTitle>
			<PatientList />
		</Grid>
	);
}

export default PatientsPage;
