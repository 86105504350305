import { jwtDecode } from 'jwt-decode';
import { Observable } from 'rxjs';
import { localStorageService } from 'services/storageService';

export type Token = string | null;

export interface AuthServiceBase {
	authorize(provider: string, ...params: unknown[]): Promise<unknown>;
	signOut(params: unknown): Promise<void>;
	getJwtPayload(): JwtPayload | null;
	getToken(): Token;
	setToken(token: Token): void;
	watchToken(): Observable<Token>;
}

export interface AuthProviderBase {
	name: string;
	authorize(...params: unknown[]): Promise<unknown>;
	signOut(): Promise<void>;
}

interface AuthServiceOptions {
	callbacks?: AuthCallbacks;
}

export interface JwtPayload {
	exp: number;
	user_id: string;
	roles: string[];
}

export interface AuthCallbacks {
	authorize?: (params: { provider: string } & Record<string, unknown>) => Promise<unknown | void>;

	token?: (params: { provider: string; token: Token }) => Promise<Token | void>;

	signOut?: (params: { provider: string }) => Promise<void>;
}

export class AuthService {
	private readonly providers: Map<string, AuthProviderBase>;

	private readonly TOKEN_KEY = 'bearer';

	private readonly callbacks: AuthCallbacks;

	constructor(providers: AuthProviderBase[], options: AuthServiceOptions) {
		this.providers = new Map<string, AuthProviderBase>();

		providers.forEach((provider) => {
			this.providers.set(provider.name, provider);
		});

		this.callbacks = options.callbacks || {};
	}

	public async authorize(providerKey: string, ...args: unknown[]): Promise<unknown> {
		const provider = this.getProvider(providerKey);
		const response = await provider.authorize(...args);

		if (this.callbacks.authorize) {
			await this.callbacks.authorize({
				provider: providerKey,
				...args,
			});
		}

		if (AuthService.isAuthTokenResponse(response)) {
			this.setToken(response.token);

			if (this.callbacks.token) {
				await this.callbacks.token({
					token: response.token,
					provider: providerKey,
				});
			}
		}

		return response;
	}

	public async signOut(providerKey: string): Promise<void> {
		const provider = this.getProvider(providerKey);
		if (provider) {
			await provider.signOut();
			this.clearAuthToken();

			if (this.callbacks.signOut) {
				await this.callbacks.signOut({
					provider: providerKey,
				});
			}
		}
	}

	public getProvider(name: string) {
		const provider = this.providers.get(name);
		if (!provider) {
			throw new Error(`Provider ${name} not found`);
		}

		return provider;
	}

	/* eslint-disable class-methods-use-this */
	public parseJwtPayload(token: Token): JwtPayload {
		if (!token) {
			throw new Error('Token is null');
		}

		return jwtDecode<JwtPayload>(token);
	}

	public setToken(newToken: Token): void {
		if (newToken) {
			localStorageService.setItem(this.TOKEN_KEY, newToken);
		} else {
			localStorageService.removeItem(this.TOKEN_KEY);
		}
	}

	public getToken(): Token {
		return localStorageService.getItem<Token>(this.TOKEN_KEY);
	}

	public clearAuthToken(): void {
		localStorageService.removeItem(this.TOKEN_KEY);
	}

	public watchToken() {
		return localStorageService.watch<string>(this.TOKEN_KEY);
	}

	private static isAuthTokenResponse(response: unknown): response is { token: Token } {
		return typeof response === 'object' && response !== null && 'token' in response;
	}
}
