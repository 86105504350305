import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from 'contexts/AuthProvider';
import { getLoginMethod, sendAuthCode, verifyAuthCode } from 'core/api/login/login.api';
import { LoginParams, TwoFactorAuthOptions } from 'core/api/login/login.models';
import { UserRole } from 'core/api/userAccount/userAccount.models';
import { IApiError } from 'core/errors/ApiError';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';

export function useAuth() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { setToken: updateToken, jwtToken: currentUserToken } = useAuthContext();

	const get2FAMethods = useMutation<
		TwoFactorAuthOptions,
		IApiError<Record<keyof LoginParams, string>>,
		LoginParams,
		unknown
	>({
		mutationKey: ['auth', 'twoFALogin', 'get2FAMethods'],
		mutationFn: getLoginMethod,
	});

	const sendAuthenticationCode = useMutation({
		mutationKey: ['auth', 'twoFALogin', 'sendAuthenticationCode'],
		mutationFn: sendAuthCode,
	});

	const getDefaultRouteForRoles = (roles: UserRole[]): string => {
		if (roles.some((role) => role === UserRole.ADMIN)) {
			return routes.admin.home;
		}

		if (
			roles.some((role) => role === UserRole.ENTERPRISE_CARE_TEAM_MEMBER || role === UserRole.ENTERPRISE_SUPERVISOR)
		) {
			return routes.enterprise.home;
		}

		return routes.home;
	};

	const verifyAuthenticationCode = useMutation({
		mutationKey: ['auth', 'twoFALogin', 'verifyAuthenticationCode'],
		mutationFn: verifyAuthCode,
		onSuccess: ({ jwtToken, roles }) => {
			queryClient.removeQueries();
			updateToken(jwtToken);
			navigate(getDefaultRouteForRoles(roles));
		},
	});

	return {
		userToken: currentUserToken,
		get2FAMethods,
		sendAuthenticationCode,
		verifyAuthenticationCode,
	};
}

export default useAuth;
