import { queryOptions } from '@tanstack/react-query';
import { getNotificationSettings, getProfileInfo, getUserMetaDataList } from 'core/api/userAccount/userAccount.api';
import { UserMetaDataParams } from 'core/api/userAccount/userAccount.models';
import authService from 'services/authService';

export const userAccountQueries = {
	userAccount: () => queryOptions({ queryKey: ['userAccount'] as const, queryFn: () => [] as const }),
	userAccountProfile: () => {
		const token = authService.getToken();

		return queryOptions({
			queryKey: [...userAccountQueries.userAccount().queryKey, 'profile'] as const,
			queryFn: getProfileInfo,
			enabled: !!token,
		});
	},
	notificationSetting: () => queryOptions({ queryKey: ['notificationSetting'] as const, queryFn: () => [] as const }),
	notificationSettingList: () =>
		queryOptions({
			queryKey: [...userAccountQueries.notificationSetting().queryKey, 'list'] as const,
			queryFn: getNotificationSettings,
		}),
	userMetaData: () =>
		queryOptions({
			queryKey: [...userAccountQueries.userAccount().queryKey, 'userMetaData'] as const,
			queryFn: () => [] as const,
		}),
	userMetaDataLists: () =>
		queryOptions({
			queryKey: [...userAccountQueries.userMetaData().queryKey, 'lists'] as const,
			queryFn: () => [] as const,
		}),
	userMetaDataList: (request?: UserMetaDataParams) =>
		queryOptions({
			queryKey: [...userAccountQueries.userMetaDataLists().queryKey, 'list', request] as const,
			queryFn: () => getUserMetaDataList(request),
		}),
};
