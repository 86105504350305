import React from 'react';
import { Grid } from '@mui/material';
import { CareTeamMemberList } from 'components/enterprise';
import AppProvider from 'contexts/AppProvider';

function CareTeamMembersPage() {
	return (
		<Grid item xs={12}>
			<AppProvider.PageTitle>Care team members</AppProvider.PageTitle>
			<CareTeamMemberList />
		</Grid>
	);
}

export default CareTeamMembersPage;
