import { queryOptions } from '@tanstack/react-query';
import { useAuthContext } from 'contexts/AuthProvider';
import { getBadges } from 'core/api/badges/badges.api';

export const getBadgesQuery = () => {
	const { jwtToken: token } = useAuthContext();

	return queryOptions({
		queryKey: ['badges'],
		queryFn: getBadges,
		enabled: !!token,
	});
};

export default getBadgesQuery;
