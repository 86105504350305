import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import useSwitchUser from 'hooks/common/useSwitchUser';

export function LoggedInAsUserBar() {
	const { stopSwitch, getActor } = useSwitchUser();
	const actor = getActor();

	if (!actor) {
		return null;
	}

	return (
		<Stack
			p={2}
			alignItems="center"
			justifyContent="center"
			textAlign="center"
			direction="row"
			sx={{ backgroundColor: 'primary.main' }}
		>
			<Typography fontSize={18} color="primary.contrastText" fontWeight={700}>
				You Are Logged In As {actor.actorName}.{' '}
				<Button
					onClick={stopSwitch}
					variant="text"
					sx={{ color: '#fff', fontWeight: 700, fontSize: 18, display: 'contents' }}
				>
					Switch Back To Your User
				</Button>
			</Typography>
		</Stack>
	);
}

export default LoggedInAsUserBar;
