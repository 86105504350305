import React from 'react';
import { Outlet } from 'react-router-dom';
import GoogleAnalytics from 'components/common/GoogleAnalytics';
import Hotjar from 'components/common/Hotjar';
import UserProvider from 'contexts/UserProvider';
import AutoLogoutProvider from 'contexts/AutoLogoutProvider';
import AppProvider from 'contexts/AppProvider';

function App() {
	return (
		<UserProvider>
			<AppProvider>
				<AutoLogoutProvider>
					<GoogleAnalytics />
					<Outlet />
					<Hotjar />
				</AutoLogoutProvider>
			</AppProvider>
		</UserProvider>
	);
}

export default App;
