import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import QueryProvider from 'contexts/QueryProvider';
import Router from 'Router';
import sentryService from 'services/sentryService';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MainThemeProvider } from 'themes/MainTheme';
import { SnackbarProvider } from 'notistack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { HelmetProvider } from 'react-helmet-async';
import AuthProvider from 'contexts/AuthProvider';
import reportWebVitals from './reportWebVitals';

sentryService.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<QueryProvider>
		<React.StrictMode>
			<MainThemeProvider>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<HelmetProvider>
						<SnackbarProvider autoHideDuration={2000}>
							<AuthProvider>
								<Router />
							</AuthProvider>
						</SnackbarProvider>
					</HelmetProvider>
				</LocalizationProvider>
			</MainThemeProvider>
		</React.StrictMode>
	</QueryProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
