import { getRequest, postRequest } from 'core/api/api-service';
import { Init } from 'core/api/init/init.models';
import { AuthProviderBase } from 'services/authService/authService';

interface RequestTwoFactorMethodsParams {
	email: string;
	password: string;
	recaptchaToken: string;
}

interface RequestTwoFactorMethodsResponse {
	message: string;
	methods: Record<string, string>;
}

interface SendAuthenticationCodeParams {
	email: string;
	password: string;
	method: number;
}

interface VerifyAuthenticationCodeParams {
	email: string;
	password: string;
	authCode: string;
}

interface VerifyAuthenticationCodeResponse {
	jwtToken: string;
	jwtRefreshToken: string;
	expiresAt: string;
	isEmailValidated: boolean;
	roles: string[];
	passwordExpired: boolean;
}

export interface CredentialsConfig {
	readonly twoFactorMethodEndpoint: string;
	readonly sendAuthCodeEndpoint: string;
	readonly verifyAuthCodeEndpoint: string;
	readonly signOutEndpoint: string;
	readonly userEndpoint: string;
}

interface User extends Init {}

type AuthorizeType = 'REQUEST_TWO_FACTOR_METHODS' | 'REQUEST_CODE' | 'VERIFY_CODE';

interface AuthState {
	email: string;
	password: string;
	methods?: Record<string, string>;
	selectedMethod?: number;
}

export class CredentialsProvider implements AuthProviderBase {
	private static instance: CredentialsProvider | null = null;

	private readonly config: CredentialsConfig;

	private authState: AuthState | null = null;

	private user: User | null = null;

	public name = 'credentialsProvider';

	private constructor(config: CredentialsConfig) {
		this.config = config;
	}

	public static getInstance(config?: CredentialsConfig): CredentialsProvider {
		if (!CredentialsProvider.instance) {
			if (!config) {
				throw new Error('Configuration is required when creating the first instance');
			}
			CredentialsProvider.instance = new CredentialsProvider(config);
		}
		return CredentialsProvider.instance;
	}

	private async requestTwoFactorMethods({
		email,
		password,
		recaptchaToken,
	}: RequestTwoFactorMethodsParams): Promise<RequestTwoFactorMethodsResponse> {
		return postRequest(this.config.twoFactorMethodEndpoint, {
			email,
			password,
			recaptchaToken,
		});
	}

	private async sendAuthenticationCode({ email, password, method }: SendAuthenticationCodeParams): Promise<void> {
		return postRequest(this.config.sendAuthCodeEndpoint, {
			email,
			password,
			method,
		});
	}

	private async verifyAuthenticationCode({
		email,
		password,
		authCode,
	}: VerifyAuthenticationCodeParams): Promise<VerifyAuthenticationCodeResponse> {
		return postRequest(this.config.verifyAuthCodeEndpoint, {
			email,
			password,
			authCode,
		});
	}

	public async authorize(
		type: AuthorizeType,
		params: RequestTwoFactorMethodsParams | SendAuthenticationCodeParams | VerifyAuthenticationCodeParams
	): Promise<void | VerifyAuthenticationCodeResponse | RequestTwoFactorMethodsResponse> {
		switch (type) {
			case 'REQUEST_TWO_FACTOR_METHODS': {
				const { email, password, recaptchaToken } = params as RequestTwoFactorMethodsParams;
				const requestTwoFactorMethodsResponse = await this.requestTwoFactorMethods({ email, password, recaptchaToken });

				this.authState = {
					email,
					password,
					methods: requestTwoFactorMethodsResponse.methods,
				};

				return requestTwoFactorMethodsResponse;
			}
			case 'REQUEST_CODE': {
				if (!this.authState) {
					throw new Error('Must call REQUEST_TWO_FACTOR_METHODS before REQUEST_CODE');
				}

				const { method } = params as SendAuthenticationCodeParams;
				this.authState.selectedMethod = method;

				return this.sendAuthenticationCode({
					email: this.authState.email,
					password: this.authState.password,
					method,
				});
			}
			case 'VERIFY_CODE': {
				if (!this.authState) {
					throw new Error('Must call REQUEST_TWO_FACTOR_METHODS before VERIFY_CODE');
				}

				const { authCode } = params as VerifyAuthenticationCodeParams;
				const verifyAuthenticationCodeResponse = await this.verifyAuthenticationCode({
					email: this.authState.email,
					password: this.authState.password,
					authCode,
				});

				const user = await getRequest<User>(
					this.config.userEndpoint,
					{},
					{
						authorization: `Bearer ${verifyAuthenticationCodeResponse.jwtToken}`,
					}
				);

				this.user = user;

				return verifyAuthenticationCodeResponse;
			}
			default:
				throw new Error('Invalid authorize parameters');
		}
	}

	public async signOut(): Promise<void> {
		await postRequest(this.config.signOutEndpoint);

		this.authState = null;
	}

	public getUser(): User | null {
		return this.user;
	}
}
