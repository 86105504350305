import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import authService, { AuthServiceBase, JwtPayload, Token } from 'services/authService';

export type { Token };

export interface IAuthProvider extends Pick<AuthServiceBase, 'signOut' | 'authorize' | 'setToken'> {
	jwtPayload: JwtPayload | null;
	jwtToken: Token;
	user: Record<string, unknown> | null;
}

export const AuthContext = createContext<IAuthProvider | undefined>(undefined);

export const useAuthContext = (): IAuthProvider => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuthContext must be used within a AuthProvider');
	}
	return context;
};

interface AuthProviderProps {
	children: React.ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
	const [jwtToken, setJwtToken] = useState<Token>(() => authService.getToken());

	const signOut = useCallback((provider: string) => authService.signOut(provider), []);
	const authorize = useCallback(
		(provider: string, params: Record<string, unknown>) => authService.authorize(provider, params),
		[]
	);
	const setToken = useCallback((newToken: Token): void => {
		authService.setToken(newToken);
	}, []);

	const jwtPayload = useMemo(() => (jwtToken ? authService.parseJwtPayload(jwtToken) : null), [jwtToken]);

	useEffect(() => {
		const subscription = authService.watchToken().subscribe((token) => {
			setJwtToken(token);
		});
		return () => subscription.unsubscribe();
	}, []);

	const value = useMemo(
		() => ({
			signOut,
			authorize,
			setToken,
			jwtPayload,
			jwtToken,
		}),
		[signOut, authorize, setToken, jwtPayload, jwtToken]
	) as IAuthProvider;

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
