import React, { useEffect, useRef } from 'react';
import { TextField, Button, Link, Stack, Typography, InputAdornment } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import { UserCredentials, MethodsMap } from 'core/api/login/login.models';
import { useAuth } from 'hooks';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import ReCAPTCHA from 'react-google-recaptcha';
import setFormErrors from 'utils/setFormErrors';
import { LEGAL_URLS } from 'constants/urls';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';
import { useAutoLogout } from 'contexts/AutoLogoutProvider';

const signInSchema = Joi.object({
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.messages({
			'*': 'Please enter a valid email address.',
		}),
	password: Joi.string().required().messages({
		'*': 'Please enter a valid password.',
	}),
});

interface LoginFormProps {
	onLoginSuccess: (data: UserCredentials & { methods: MethodsMap }) => void;
}

export function LoginForm({ onLoginSuccess }: LoginFormProps) {
	const { get2FAMethods } = useAuth();
	const { isExpired } = useAutoLogout();
	const { mutate: onLogin, isPending } = get2FAMethods;
	const recaptchaRef = useRef<ReCAPTCHA | null>(null);
	const {
		control,
		handleSubmit,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm<UserCredentials>({
		mode: 'onChange',
		resolver: joiResolver(signInSchema),
	});

	const handleLogin = async ({ email, password }: UserCredentials) => {
		clearErrors();

		if (recaptchaRef?.current) {
			const recaptchaToken = (await recaptchaRef.current.executeAsync()) || '';

			onLogin(
				{ email, password, recaptchaToken },
				{
					onSuccess: ({ methods }) =>
						onLoginSuccess({
							email,
							password,
							methods,
						}),
					onError: (error) => {
						recaptchaRef?.current?.reset();

						setFormErrors(error, setError);
					},
				}
			);
		} else {
			setError('root.recaptchaError', {
				type: 'recaptcha',
				message: 'ReCAPTCHA was not initialized properly. Please refresh the page and try again.',
			});
		}
	};

	useEffect(() => {
		if (isExpired) {
			setError('root.sessionExpired', {
				type: 'sessionExpired',
				message: 'You have been automatically logged out. Please log in again.',
			});
		}
	}, [isExpired, setError]);

	return (
		<>
			<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="text.secondary">
				Login to your ExactCare account
			</Typography>
			<Typography component="span" color="text.secondary" variant="body1">
				Securely access your medication and account information any time.
			</Typography>
			<Stack direction="column" gap={1} component="form" onSubmit={handleSubmit(handleLogin)}>
				<Controller
					name="email"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							error={!!error}
							helperText={error?.message}
							value={field.value || ''}
							label="Email"
							autoComplete="username"
							variant="standard"
							size="small"
							margin="normal"
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<AlternateEmailIcon />
									</InputAdornment>
								),
							}}
							InputLabelProps={{
								required: false,
							}}
						/>
					)}
				/>
				<Controller
					name="password"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<PasswordField
							{...field}
							error={!!error}
							helperText={error?.message}
							value={field.value || ''}
							label="Password"
							autoComplete="current-password"
							variant="standard"
							size="small"
							margin="normal"
							disableTooltip
							fullWidth
						/>
					)}
				/>
				<Stack alignItems="flex-end">
					<Link
						component={RouterLink}
						to={routes.forgotPassword}
						variant="body1"
						fontWeight="bold"
						underline="none"
						color="text.primary"
					>
						Forgot Password
					</Link>
				</Stack>
				<ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''} size="invisible" />
				<FormErrorMessages errors={errors} name="root" />
				<Stack gap={3}>
					<Button
						disabled={isPending && !recaptchaRef?.current}
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
					>
						{isPending ? 'Signing In...' : 'Sign In'}
					</Button>
					<Button
						to={generatePath(routes.register)}
						component={RouterLink}
						fullWidth
						variant="outlined"
						color="inherit"
						sx={{ textTransform: 'none', color: 'text.secondary' }}
					>
						Don&apos;t have an account? Get one!
					</Button>
				</Stack>
			</Stack>
			<Stack direction="row" gap={2} mt={4}>
				<Link href={LEGAL_URLS.TERMS_AND_CONDITIONS} target="_blank" color="text.secondary">
					Terms and Conditions
				</Link>
				<Link href={LEGAL_URLS.PRIVACY_POLICY} target="_blank" color="text.secondary">
					Privacy Policy
				</Link>
			</Stack>
		</>
	);
}

export default LoginForm;
