import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loginAsCareTeamMember } from 'core/api/admin/enterprise.api';
import { loginAsPatient } from 'core/api/admin/patients.api';
import { localStorageService } from 'services/storageService';
import routes from 'routes';
import { useAuthContext, Token } from 'contexts/AuthProvider';

export enum ActorType {
	PATIENT = 'PATIENT',
	CARE_TEAM_MEMBER = 'CARE_TEAM_MEMBER',
}

interface Actor {
	actorToken: Token;
	actorName: string;
	actorEmail: string;
}

export function useSwitchUser() {
	const { setToken: updateToken, jwtToken: currentUserToken } = useAuthContext();
	const queryClient = useQueryClient();
	const [isSwitching, setIsSwitching] = useState(false);

	const getActor = () => localStorageService.getItem<Actor>('actor');
	const setActor = (actor: Actor) => localStorageService.setItem('actor', actor);

	const startSwitch = useMutation({
		mutationFn: ({ targetUserId, type }: { targetUserId: string; type: ActorType }) => {
			setIsSwitching(true);
			if (type === ActorType.PATIENT) {
				return loginAsPatient({ id: targetUserId });
			}

			return loginAsCareTeamMember({ id: targetUserId });
		},
		onSuccess: ({ userFullName, adminEmail, userToken }) => {
			const actorData: Actor = {
				actorToken: currentUserToken,
				actorName: userFullName,
				actorEmail: adminEmail,
			};

			updateToken(userToken);
			setActor(actorData);
			setIsSwitching(false);

			window.open(routes.home, '_blank');
		},
		onError: () => {
			setIsSwitching(false);
		},
	});

	const stopSwitch = useCallback(() => {
		const actor = getActor();

		if (actor) {
			updateToken(actor?.actorToken);
			setIsSwitching(true);
			localStorageService.removeItem('actor');
			window.close();
		}
	}, [getActor, updateToken]);

	useEffect(() => {
		if (currentUserToken && isSwitching) {
			queryClient.removeQueries();
			setIsSwitching(false);
		}
	}, [currentUserToken, isSwitching]);

	return {
		startSwitch: startSwitch.mutateAsync,
		stopSwitch,
		getActor,
		isLoading: startSwitch.isPending,
	};
}

export default useSwitchUser;
