import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import routes from 'routes';
import { SUPPORT_URLS } from 'constants/urls';
import { useUserContext } from 'contexts/UserProvider';
import { HomeMobIcon } from 'components/common/icons/HomeMobIcon';
import { ContractEditMobIcon } from 'components/common/icons/ContractEditMobIcon';
import { MedsMobIcon } from 'components/common/icons/MedsMobIcon';
import { LocalShippingMobIcon } from 'components/common/icons/LocalShippingMobIcon';

export const useMainMenu = () => {
	const { badges } = useUserContext();

	return useMemo(
		() => [
			{
				label: 'Home',
				icon: HomeMobIcon,
				to: routes.home,
				notifications: badges.homeButtonBadge,
				activeRoutes: [
					routes.home,
					routes.inbox.list,
					routes.inbox.item,
					routes.mySchedule.reviewMyHistory.calendar,
					routes.mySchedule.reviewMyHistory.day,
					routes.mySchedule.manageMedicationReminders,
				],
			},
			{
				label: 'Today',
				icon: ContractEditMobIcon,
				to: generatePath(routes.mySchedule.todaysMedication),
				activeRoutes: [routes.mySchedule.todaysMedication, routes.mySchedule.logAsNeededMed],
			},
			{
				icon: MedsMobIcon,
				label: 'My Meds',
				to: generatePath(routes.medication),
				notifications: badges.medicationButtonBadge ? '!' : undefined,
				activeRoutes: [routes.medication],
			},
			{
				label: 'Deliveries',
				icon: LocalShippingMobIcon,
				to: generatePath(routes.delivery),
				activeRoutes: [routes.delivery],
			},
			{
				label: 'Help',
				to: SUPPORT_URLS.HELP_CENTER,
				target: '_blank',
			},
		],
		[badges]
	);
};
