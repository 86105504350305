import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { Init, UserDto } from 'core/api/init/init.models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import initQuery from 'queries/initQuery';
import { AuthError } from 'core/errors/AuthError';
import { logoutCall } from 'core/api/login/login.api';
import routes from 'routes';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthProvider';
import Suspense from 'components/common/Suspense';
import { BadgeResults, useBadges } from 'hooks/patient/useBadges';

export interface IUserContext extends UserDto, Pick<Init, 'anyOrderDelivered' | 'currentOrderToday' | 'homepage'> {
	isLoading: boolean;
	badges: Exclude<BadgeResults, 'isLoading'>;
	logout: () => void;
	programmaticLogout: () => void;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

export const useUserContext = (): IUserContext => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext must be used within a UserProvider');
	}
	return context;
};

interface UserProviderProps {
	children: React.ReactNode;
}

export default function UserProvider({ children }: UserProviderProps) {
	const { setToken } = useAuthContext();
	const { isLoading: isLoadingBadges, ...badges } = useBadges();
	const { data: initData, isLoading } = useQuery(initQuery());
	const { user, anyOrderDelivered, currentOrderToday, homepage } = initData || {};
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const logoutMutation = useMutation({
		mutationFn: logoutCall,
		mutationKey: ['logout', 'normal'],
		onSuccess: () => {
			queryClient.removeQueries();
			setToken(null);
			navigate(routes.login);
		},
		onError: (error) => {
			if (error instanceof AuthError) {
				queryClient.removeQueries();
				setToken(null);
				navigate(routes.login);
			}
		},
	});

	const programmaticLogoutMutation = useMutation({
		mutationFn: logoutCall,
		mutationKey: ['logout', 'programmatic'],
		onSuccess: () => {
			queryClient.removeQueries();
			setToken(null);
			navigate(routes.login);
		},
		onError: (error) => {
			if (error instanceof AuthError) {
				queryClient.removeQueries();
				setToken(null);
				navigate(routes.login);
			}
		},
	});

	const logout = useCallback(() => {
		logoutMutation.mutate();
	}, [logoutMutation]);

	const programmaticLogout = useCallback(() => {
		programmaticLogoutMutation.mutate();
	}, [programmaticLogoutMutation]);

	const value = useMemo(
		() => ({
			isLoading,
			...user,
			badges,
			anyOrderDelivered,
			currentOrderToday,
			homepage,
			logout,
			programmaticLogout,
		}),
		[isLoading, badges, anyOrderDelivered, currentOrderToday, homepage, logout, programmaticLogout, user]
	) as IUserContext;

	return (
		<UserContext.Provider value={value}>
			<Suspense isLoading={isLoading}>{children}</Suspense>
		</UserContext.Provider>
	);
}
