import React from 'react';
import { Grid, Link, Stack, Switch, Chip, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import { Paper, PaperTitle } from 'components/common/dashboardLayout';
import AppProvider from 'contexts/AppProvider';
import { loader } from 'components/admin/AdminPatientLayout/AdminPatientLayout';
import { usePatientDetailsQuery } from 'hooks/admin/usePatientDetailsQuery';

function PatientsSummaryPage() {
	const initialPatientData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
	const { userId: patientId } = initialPatientData;
	const { data: patientData } = usePatientDetailsQuery({ id: patientId.toString() }, initialPatientData);

	return (
		<>
			<AppProvider.PageTitle>Patient Details</AppProvider.PageTitle>
			<Grid item xs={12}>
				<Paper>
					<PaperTitle>Personal Info</PaperTitle>
					<List disablePadding>
						<ListItem disableGutters>
							<ListItemText
								primary="Full Name"
								secondary={patientData?.fullName}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="Email"
								secondary={patientData?.email}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="Phone"
								secondary={patientData?.phone}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="Date of Birth"
								secondary={patientData?.dateOfBirth}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
					</List>
				</Paper>
			</Grid>
			<Grid item xs={12} md={4}>
				<Paper>
					<PaperTitle>Identifiers</PaperTitle>
					<List disablePadding>
						<ListItem disableGutters>
							<ListItemText
								primary="ECA User ID"
								secondary={patientData?.userId}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="Account SFID"
								secondary={
									<Link href={patientData?.salesforceUrl} target="_blank">
										{patientData?.accountSfid}
									</Link>
								}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
					</List>
				</Paper>
			</Grid>
			<Grid item xs={12} md={4}>
				<Paper>
					<PaperTitle>Security</PaperTitle>
					<List disablePadding>
						<ListItem disableGutters>
							<ListItemText
								primary={
									<Typography variant="body1" fontWeight="bold" color="text.secondary">
										Roles
									</Typography>
								}
								secondary={
									<Stack direction="row" spacing={1}>
										{patientData?.roles.map((role) => <Chip key={role} label={role} size="small" />)}
									</Stack>
								}
								disableTypography
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="2FA Bypass code"
								secondary={patientData?.bypassAuthCode}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="Last Login At"
								secondary={patientData?.lastLoginAt}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="Created At"
								secondary={patientData?.createdAt}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="Updated At"
								secondary={patientData?.updatedAt}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
					</List>
				</Paper>
			</Grid>
			<Grid item xs={12} md={4}>
				<Paper>
					<PaperTitle>Med Reminders</PaperTitle>
					<List disablePadding>
						<ListItem disableGutters>
							<ListItemText
								primary="Enabled"
								secondary={<Switch checked={patientData?.medicationRemindersEnabled} disabled />}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
						<ListItem disableGutters>
							<ListItemText
								primary="Timezone"
								secondary={patientData?.timeZone}
								type="inline"
								primaryTypographyProps={{
									fontWeight: 'bold',
								}}
							/>
						</ListItem>
					</List>
				</Paper>
			</Grid>
		</>
	);
}

export default PatientsSummaryPage;
