import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISuccessResponse } from 'core/api/api-service.models';
import { changePasswordRequest } from 'core/api/userAccount/userAccount.api';
import { IChangePasswordRequest } from 'core/api/userAccount/userAccount.models';
import { IApiError } from 'core/errors/ApiError';
import { userAccountQueries } from 'queries/patient';

export function useChangePasswordMutation() {
	const queryClient = useQueryClient();

	return useMutation<
		ISuccessResponse,
		IApiError<Record<keyof IChangePasswordRequest, string>>,
		IChangePasswordRequest,
		unknown
	>({
		mutationKey: ['changePassword'],
		mutationFn: changePasswordRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: userAccountQueries.userAccountProfile().queryKey });
		},
	});
}

export default useChangePasswordMutation;
