import React from 'react';
import { Stack, Box, Typography, Button, Divider, Card as MuiCard, styled } from '@mui/material';
import { LoaderFunctionArgs, Link as RouterLink } from 'react-router-dom';
import AnewHealthExactCareLightLogo from 'components/common/logo/AnewHealthExactCareLightLogo';
import routes from 'routes';
import LoginAdminButton from 'components/admin/login/LoginAdminButton';
import authService from 'services/authService';

const Card = styled(MuiCard)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignSelf: 'center',
	width: '100%',
	padding: theme.spacing(4),
	gap: theme.spacing(2),
	margin: 'auto',
	[theme.breakpoints.up('sm')]: {
		maxWidth: '450px',
	},
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
	height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
	minHeight: '100%',
	padding: theme.spacing(2),
	justifyContent: 'center',
	[theme.breakpoints.up('sm')]: {
		padding: theme.spacing(4),
	},
}));

export const loader =
	() =>
	async ({ request }: LoaderFunctionArgs) => {
		const { searchParams } = new URL(request.url);
		const code = searchParams.get('code');

		if (!code) {
			return null;
		}

		return authService.authorize('ecaAdminOAuthProvider', code);
	};

export default function LoginPage() {
	return (
		<SignInContainer direction="column" justifyContent="space-between">
			<Stack gap={4} direction="column" justifyContent="center" alignItems="center">
				<AnewHealthExactCareLightLogo sx={{ width: 320, height: 'auto', display: 'block' }} />
				<Card variant="outlined">
					<Typography component="h1" variant="h4">
						Sign in
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
						<LoginAdminButton />
						<Divider>OR</Divider>
						<Button
							to={routes.login}
							component={RouterLink}
							fullWidth
							variant="outlined"
							sx={{
								textTransform: 'none',
							}}
						>
							Sign in with Patient/Enterprise Account
						</Button>
					</Box>
				</Card>
			</Stack>
		</SignInContainer>
	);
}
