import React from 'react';
import Button from '@mui/material/Button';
import { SalesforceIcon } from 'components/common/icons/SalesforceIcon';
import authService from 'services/authService';

export default function LoginAdminButton() {
	return (
		<Button
			onClick={() => authService.authorize('ecaAdminOAuthProvider')}
			fullWidth
			variant="outlined"
			startIcon={<SalesforceIcon />}
			sx={{
				color: '#039ddd',
				borderColor: '#039ddd',
				textTransform: 'none',
			}}
		>
			Sign in with Salesforce
		</Button>
	);
}
