import { PatientStatus } from 'core/models/admin/patients.models';
import { HydraCollection } from '../api-service.models';

export interface IPatientsListRequest {
	page: number;
	itemsPerPage: number;
	search?: string;
	dob?: string;
	status?: string;
}

export interface IPatientResponse {
	userId: number;
	fullName: string | null;
	dateOfBirth: string | null;
	email: string;
	phone: string | null;
	createdAt: string;
	lastLoginAt: string;
	status: PatientStatus;
}

export interface IGetPatientsListResponse extends HydraCollection<IPatientResponse> {}

export interface IGetPatientRequest {
	userId: string;
}

export interface IDeletePatientRequest extends IGetPatientRequest {}

export interface IUnblockPatientRequest extends IGetPatientRequest {}

export interface ISuspendPatientRequest extends IGetPatientRequest {
	suspend: boolean;
}

export interface IChangeEmailPatientRequest extends IGetPatientRequest {
	email: string;
}

export interface IChangeDateOfBirthPatientRequest extends IGetPatientRequest {
	dateOfBirth: string | null;
}

export interface IChangePasswordPatientRequest extends IGetPatientRequest {
	newPassword: string | null;
}

export interface IChangeBypassAuthCodeRequest extends IGetPatientRequest {
	bypassAuthCode: string | null;
}

export interface UserLoginAsParams {
	id: string;
}

export interface UserLoginAs {
	adminEmail: string;
	userFullName: string;
	userToken: string;
}

export interface PatientDetailsParams {
	id: string;
}

export interface PatientDetails {
	userId: number;
	accountSfid: string;
	salesforceUrl: string;
	fullName: string;
	email: string;
	phone: string;
	dateOfBirth: string;
	roles: string[];
	bypassAuthCode: string;
	lastLoginAt: string;
	createdAt: string;
	updatedAt: string;
	medicationRemindersEnabled: boolean;
	timeZone: string;
	status: PatientStatus;
}

export enum PatientNotificationStatus {
	PENDING = 0,
	SENDING = 1,
	SENT = 2,
	ERROR = 3,
	EXPIRED = 4,
}

export enum PatientNotificationChannel {
	SMS = 'sms',
	EMAIL = 'email',
	PUSH = 'push',
}

export interface PatientNotificationsParams {
	patientId?: string;
	page?: number;
	itemsPerPage?: number;
	orderBy?: 'id' | 'statusId';
	orderDirection?: 'ASC' | 'DESC';
}

export interface PatientNotification {
	id: number;
	channel: PatientNotificationChannel;
	code: string;
	sentAt: string;
	statusId: PatientNotificationStatus;
	createdAt: string;
	updatedAt: string;
}

export interface PatientNotificationsResponse extends HydraCollection<PatientNotification> {}

export interface MedsProcessingJobParams {
	patientId?: string;
	page?: number;
	itemsPerPage?: number;
	orderBy?: 'id' | 'status' | 'createdAt' | 'updatedAt';
	orderDirection?: 'ASC' | 'DESC';
}

export enum MedsProcessingJobStatus {
	PENDING = 0,
	PROCESSING = 1,
	SUCCESS = 2,
	ERROR = 3,
}

export interface MedsProcessingJob {
	id: number;
	triggerType: string;
	notes: string;
	from: string;
	to: string;
	status: MedsProcessingJobStatus;
	createdAt: string;
	updatedAt: string;
}

export interface MedsProcessingJobsResponse extends HydraCollection<MedsProcessingJob> {}

export interface CreateMedsProcessingJobsParams {
	patientId: string;
	from: string;
	to: string;
}

export interface CreateMedsProcessingJobsResponse {
	from: string;
	to: string;
}

export interface UserMetaDataParams {
	userId: string;
	key?: string | string[];
}

export interface UserMetaData {
	id: number;
	key: string;
	value: string;
}

export interface UserMetaDataResponse extends HydraCollection<UserMetaData> {}

export interface DeleteUserMetaDataParams {
	userId: string;
	id: string;
}

export enum PushDeviceOS {
	IOS = 1,
	ANDROID = 2,
}

export interface PatientPushDevicesParams {
	patientId?: string;
	page?: number;
	itemsPerPage?: number;
	orderBy?: 'id';
	orderDirection?: 'ASC' | 'DESC';
}

export interface PatientPushDevice {
	id: number;
	deviceModel: string;
	deviceOs: PushDeviceOS;
	deviceToken: string;
	createdAt: string;
	updatedAt: string;
}

export interface PatientPushDevicesResponse extends HydraCollection<PatientPushDevice> {}

export interface PatientChangePasswordFormFields {
	newPassword: string;
	repeatNewPassword: string;
}
