import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import { useUserContext } from 'contexts/UserProvider';
import { useAuthContext } from 'contexts/AuthProvider';
import { localStorageService } from 'services/storageService';
import InactivityDialog from './InactivityDialog';

interface AutoLogoutContextType {
	showInactivityDialog: boolean;
	lastActivity: number;
	resetInactivity: () => void;
	isExpired: boolean;
}

const AutoLogoutContext = createContext<AutoLogoutContextType | null>(null);

const MINUTE_IN_MS = 60 * 1000;
const WARNING_TIME = MINUTE_IN_MS;
const CHECK_INTERVAL = 1000;
const ACTIVITY_EVENTS = ['keydown', 'click'] as const;
const STORAGE_KEY = 'lastActivity';

export const useAutoLogout = () => {
	const context = useContext(AutoLogoutContext);
	if (!context) {
		throw new Error('useAutoLogout must be used within AutoLogoutProvider');
	}
	return context;
};

interface AutoLogoutProviderProps {
	children: React.ReactNode;
}

export default function AutoLogoutProvider({ children }: AutoLogoutProviderProps) {
	const autoLogoutTime = useMemo(() => {
		const configuredMinutes = Number(process.env.REACT_APP_AUTO_LOGOUT_TIME);
		if (!configuredMinutes) return 0;
		return configuredMinutes * MINUTE_IN_MS;
	}, []);

	const isMutatingLogout = useIsMutating({ mutationKey: ['logout', 'normal'] });
	const { jwtToken: userToken } = useAuthContext();
	const { logout, programmaticLogout } = useUserContext();
	const [showInactivityDialog, setShowInactivityDialog] = useState(false);
	const [isExpired, setIsExpired] = useState(false);

	const activityManager = useMemo(
		() => ({
			update: () => localStorageService.setItem(STORAGE_KEY, Date.now().toString()),
			get: () => Number(localStorageService.getItem(STORAGE_KEY)),
			clear: () => localStorageService.removeItem(STORAGE_KEY),
			getTimeSinceLastActivity: () => {
				const lastActivity = Number(localStorageService.getItem(STORAGE_KEY));
				return lastActivity ? Date.now() - lastActivity : 0;
			},
		}),
		[]
	);

	const handleResetInactivity = useCallback(() => {
		setShowInactivityDialog(false);
		setIsExpired(false);
		activityManager.clear();
	}, [activityManager]);

	const checkInactivity = useCallback(() => {
		if (!autoLogoutTime) return;

		const timeSinceLastActivity = activityManager.getTimeSinceLastActivity();
		if (!timeSinceLastActivity) {
			handleResetInactivity();
			return;
		}

		const timeUntilLogout = autoLogoutTime - timeSinceLastActivity;
		const shouldShowWarning = !!userToken && timeUntilLogout <= WARNING_TIME && timeUntilLogout > 0;
		setShowInactivityDialog(shouldShowWarning);
		setIsExpired(timeUntilLogout <= 0);

		if (timeUntilLogout <= 0 && !!userToken) {
			programmaticLogout();
		}
	}, [userToken, isExpired, autoLogoutTime, activityManager, handleResetInactivity]);

	const setupActivityTracking = useCallback(() => {
		if (!userToken) return;

		activityManager.update();

		const handleActivity = () => {
			activityManager.update();
		};

		ACTIVITY_EVENTS.forEach((event) => window.addEventListener(event, handleActivity, { passive: true }));

		return () => {
			ACTIVITY_EVENTS.forEach((event) => window.removeEventListener(event, handleActivity));
		};
	}, [userToken, activityManager]);

	useEffect(() => {
		if (isMutatingLogout) {
			handleResetInactivity();
		}
	}, [isMutatingLogout, handleResetInactivity]);

	useEffect(() => {
		const interval = setInterval(checkInactivity, CHECK_INTERVAL);
		return () => clearInterval(interval);
	}, [checkInactivity]);

	useEffect(() => {
		return setupActivityTracking();
	}, [setupActivityTracking]);

	const contextValue = useMemo(
		() => ({
			isExpired,
			showInactivityDialog,
			lastActivity: activityManager.get(),
			resetInactivity: handleResetInactivity,
		}),
		[showInactivityDialog, activityManager, handleResetInactivity, isExpired]
	);

	return (
		<AutoLogoutContext.Provider value={contextValue}>
			{showInactivityDialog && <InactivityDialog lastActivity={activityManager.get()} onLogout={logout} />}
			{!showInactivityDialog && children}
		</AutoLogoutContext.Provider>
	);
}
