import routes from 'routes';
import { QueryClient } from '@tanstack/react-query';
import { AuthService } from './authService';
import ecaAdminOAuthProvider from './providers/ecaAdminOAuthProvider';
import credentialsProvider from './providers/credentialsProvider';

const API_URL = process.env.REACT_APP_API_URL;

const queryClient = new QueryClient();

const authService = new AuthService(
	[
		ecaAdminOAuthProvider({
			authorizationEndpoint: `${API_URL}/connect/salesforce`,
			tokenEndpoint: `${API_URL}/api/admin/login/check/salesforce`,
			signOutEndpoint: `${API_URL}/api/admin/logout`,
			userEndpoint: `${API_URL}/api/admin/init`,
		}),
		credentialsProvider({
			twoFactorMethodEndpoint: `${API_URL}/api/login/2fa_method`,
			sendAuthCodeEndpoint: `${API_URL}/api/login/send_authentication_code`,
			verifyAuthCodeEndpoint: `${API_URL}/api/login/verify_authentication_code`,
			signOutEndpoint: `${API_URL}/api/logout`,
			userEndpoint: `${API_URL}/api/init`,
		}),
	],
	{
		callbacks: {
			token: async ({ provider }) => {
				queryClient.removeQueries();
				if (provider === 'ecaAdminOAuthProvider') {
					window.location.href = routes.admin.patients.list;
				} else if (provider === 'credentialsProvider') {
					window.location.href = routes.home;
				}
			},
			signOut: async () => {
				queryClient.removeQueries();
				window.location.href = routes.login;
			},
		},
	}
);

export type { AuthServiceBase, JwtPayload, Token } from './authService';

export default authService;
