import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Paper, Stack, Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

interface InactivityDialogProps {
	lastActivity: number;
	onLogout: () => void;
}

const InactivityDialog = memo(({ lastActivity, onLogout }: InactivityDialogProps) => {
	const timeCalculations = useMemo(() => {
		const timeSinceLastActivity = Date.now() - lastActivity;
		const remainingTime = Math.floor(timeSinceLastActivity / 1000);

		return remainingTime;
	}, [lastActivity]);

	const [timeLeft, setTimeLeft] = useState(timeCalculations);
	const intervalRef = useRef<NodeJS.Timer | null>(null);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setTimeLeft((prev) => {
				if (prev === 0) {
					clearInterval(intervalRef.current as NodeJS.Timer);
					return prev;
				}
				return prev - 1;
			});
		}, 1000);

		return () => clearInterval(intervalRef.current as NodeJS.Timer);
	}, []);

	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			sx={{
				minHeight: '100vh',
				backgroundColor: 'hsla(278,100%,6%,1)',
				backgroundImage:
					'radial-gradient(at 76% 58%, hsla(278,100%,6%,0.81) 0px, transparent 50%), radial-gradient(at 26% 24%, hsla(278,100%,6%,0.81) 0px, transparent 50%), radial-gradient(at 35% 99%, hsla(206,80%,43%,0.62) 0px, transparent 50%), radial-gradient(at 62% 1%, hsla(283,100%,28%,0.71) 0px, transparent 50%)',
			}}
			data-testid="inactivity-dialog"
		>
			<Paper elevation={0} sx={{ maxWidth: 'lg', padding: '4%' }}>
				<Stack direction="column" gap={2} alignItems="center">
					<AccountCircleOutlinedIcon sx={{ color: 'grey.400', fontSize: 80 }} />
					<Typography component="h1" variant="h1" fontWeight={700}>
						You’re about to be logged out!
					</Typography>
					<Typography component="p">
						Due to inactivity, your current session is about to expire. For your account security, you will be logged
						out in {timeLeft} seconds.
					</Typography>
					<Typography component="p" fontWeight={700}>
						Click anywhere to continue or log out by clicking below.
					</Typography>
					<Button onClick={onLogout} variant="contained" color="primary" sx={{ width: '20%', minWidth: '200px' }}>
						Log Out
					</Button>
				</Stack>
			</Paper>
		</Stack>
	);
});

InactivityDialog.displayName = 'InactivityDialogX';

export default InactivityDialog;
