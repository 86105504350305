import React from 'react';
import {
	Box,
	Link,
	Grid,
	Toolbar,
	Typography,
	IconButton,
	Container,
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import routes from 'routes';
import isActiveRoute from 'utils/isActiveRoute';
import { useDisclosure } from 'hooks';
import Breadcrumbs from 'components/common/dashboardLayout/Breadcrumbs';
import AnewHealthExactCareLightLogo from 'components/common/logo/AnewHealthExactCareLightLogo';
import { AppBar, Drawer } from './DashboardLayout.styles';

function Copyright() {
	return (
		<Typography variant="body2" color="text.primary" align="center" sx={{ pt: 4 }}>
			{'Copyright © '}
			<Link color="inherit" to={routes.admin.home} component={RouterLink}>
				ExactCare Pharmacy
			</Link>{' '}
			{new Date().getFullYear()}.
		</Typography>
	);
}

interface DashboardLayoutProps {
	children: React.ReactNode;
	pageTitle: string;
	mainMenu: { to: string; activeRoutes: string[]; label: string; icon: React.ReactElement }[];
	className?: string;
	email: string;
	logout: () => void;
}

export function DashboardLayout({ children, pageTitle, mainMenu, className, email, logout }: DashboardLayoutProps) {
	const location = useLocation();
	const { isOpen: isOpenDrawer, onToggle: onToggleDrawer } = useDisclosure({ isOpen: true });

	return (
		<Box sx={{ display: 'flex', position: 'relative' }} className={className}>
			<AppBar position="absolute" open={isOpenDrawer}>
				<Toolbar
					sx={{
						pr: '24px',
					}}
				>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={onToggleDrawer}
						sx={{
							marginRight: '36px',
							...(isOpenDrawer && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
						{pageTitle}
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
						<Typography>Hi! {email}</Typography>
						<IconButton onClick={logout} color="inherit" title="Logout">
							<LogoutIcon />
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={isOpenDrawer}>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						px: [1],
					}}
				>
					<Link to={routes.home} component={RouterLink}>
						<AnewHealthExactCareLightLogo sx={{ width: 190, height: 'auto', display: 'block' }} />
					</Link>
					<IconButton onClick={onToggleDrawer}>
						<ChevronLeftIcon />
					</IconButton>
				</Toolbar>
				<Divider />
				<List component="nav">
					{mainMenu.map((item) => (
						<ListItemButton
							key={item.label}
							to={item.to}
							component={RouterLink}
							selected={isActiveRoute(location.pathname, item.activeRoutes)}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.label} />
						</ListItemButton>
					))}
				</List>
			</Drawer>
			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto',
				}}
			>
				<Toolbar />
				<Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sx={{ paddingTop: '6px !important' }}>
							<Breadcrumbs />
						</Grid>
						{children}
					</Grid>
					<Copyright />
				</Container>
			</Box>
		</Box>
	);
}

export default DashboardLayout;
